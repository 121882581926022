/**
 * @file Hook to create a drop
 * @author Alwyn Tan
 */

import { useMutation, useQueryClient } from 'react-query'
import { serialize } from 'object-to-formdata'
import { useContext } from 'react'
import RootContext from 'RootContext'
import { compressThumbnailImage } from 'utils'

const CREATE_DROP_URL = '/api/drop'

const postCreateDrop = async (fields, accessToken) =>
  fetch(CREATE_DROP_URL, {
    method: 'POST',
    body: serialize(fields),
    headers: { Authorization: `Bearer ${accessToken}` },
  }).then(response => response.json())

const useCreateDrop = () => {
  const { user } = useContext(RootContext)
  const queryClient = useQueryClient()

  return useMutation(async fields => {
    const updatedFields = { ...fields }
    if (fields.thumbnail)
      updatedFields.thumbnail = await compressThumbnailImage(fields.thumbnail)

    const { drop } = await postCreateDrop(updatedFields, user.accessToken)
    if (drop) {
      queryClient.invalidateQueries('drops')
    }
    return { drop }
  })
}

export default useCreateDrop
