/**
 * @file General Input For Solo Party
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import NumberFormat from 'react-number-format'
import isMobilePhone from 'validator/lib/isMobilePhone'
import { useController } from 'react-hook-form'

const Container = styled.div`
  background-color: #171818;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 15px;
  color: white;
  border: ${({ error }) => (error ? 1 : 0)}px solid red;

  > input {
    color: white;
    background-color: #171818;
    outline: none;
    border: 0;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    padding: 0;
    padding-left: 5px;

    ::placeholder {
      opacity: 0.5;
    }
  }
`

const CountryFlag = styled.img`
  height: 15px;
  margin-top: 1px;
`

const CountryCode = styled.p`
  transform: translateY(0.5px);
`

const Divider = styled.div`
  height: 20px;
  width: 2px;
  border-radius: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 0px 10px;
`

const Error = styled.p`
  padding: 5px 0 0 10px;
  color: ${({ theme }) => theme.Error};
`

const PhoneInput = ({ name, control, defaultValue, required }) => {
  const countryCode = '+1'

  const { field, fieldState } = useController({
    name,
    control,
    defaultValue,
    rules: {
      required,
      validate: value =>
        isMobilePhone(value || '') || 'Enter a valid US phone number',
    },
  })

  const transform = {
    input: value => value.replace(/[\s()-]+/g, '').replace(countryCode, ''),
    output: value => `${countryCode}${value.replace(/[\s()-]+/g, '')}`,
  }

  const hideError = field.value.length < 12

  return (
    <>
      <Container error={!hideError && fieldState.error}>
        <CountryFlag src="https://catamphetamine.gitlab.io/country-flag-icons/3x2/US.svg" />
        <Divider />
        <CountryCode>{countryCode}</CountryCode>
        <NumberFormat
          format="(###) ### ####"
          mask=" "
          placeholder="(000) 000 0000"
          {...field}
          value={transform.input(field.value)}
          onChange={e => field.onChange(transform.output(e.target.value))}
        />
      </Container>
      {!hideError && fieldState.error && (
        <Error>{fieldState.error?.message}</Error>
      )}
    </>
  )
}

PhoneInput.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
}

PhoneInput.defaultProps = {
  defaultValue: '',
  required: false,
}

export default PhoneInput
