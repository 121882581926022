import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import RootContext from 'RootContext'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

const queryClient = new QueryClient()

const theme = {
  Error: '#ff0033',
  Action: '#9E24BF',
}

const Root = () => {
  const [user, setUser] = useState()

  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <RootContext.Provider value={{ user, setUser }}>
            <ThemeProvider theme={theme}>
              <App />
            </ThemeProvider>
          </RootContext.Provider>
        </BrowserRouter>
      </QueryClientProvider>
    </React.StrictMode>
  )
}

ReactDOM.render(<Root />, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
