/**
 * @file Hook to update a drop
 * @author Alwyn Tan
 */

import { useMutation, useQueryClient } from 'react-query'
import { serialize } from 'object-to-formdata'
import { useContext } from 'react'
import RootContext from 'RootContext'
import { compressThumbnailImage } from 'utils'

const UPDATE_DROP_URL = '/api/drop'

const putUpdateDrop = async (id, fields, accessToken) =>
  fetch(`${UPDATE_DROP_URL}/${id}`, {
    method: 'PUT',
    body: serialize(fields),
    headers: { Authorization: `Bearer ${accessToken}` },
  }).then(response => response.json())

const useUpdateDrop = () => {
  const { user } = useContext(RootContext)
  const queryClient = useQueryClient()

  return useMutation(async ({ id, fields }) => {
    const updatedFields = { ...fields }
    if (fields.thumbnail)
      updatedFields.thumbnail = await compressThumbnailImage(fields.thumbnail)

    const { drop } = await putUpdateDrop(id, updatedFields, user.accessToken)
    if (drop) {
      queryClient.invalidateQueries('drops')
    }
    return { drop }
  })
}

export default useUpdateDrop
