/**
 * @file Auth Screen
 * @author Alwyn Tan
 */

import React, { useState } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import PhoneInput from 'components/simple/PhoneInput'
import useRequestLogin from 'hooks/query/user/useRequestLogin'
import Input from 'components/simple/Input'
import useLogin from 'hooks/query/user/useLogin'

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
`

const Form = styled.form`
  width: 335px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Button = styled.button`
  background-color: ${({ theme }) => theme.Action};
  margin-top: 10px;
  width: 100%;
  outline: none;
  border: 0;
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 1.1rem;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`

const LogoText = styled.span`
  font-family: Hobeaux;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  text-transform: uppercase;
  color: white;
  margin-bottom: 15px;
  transform: scale(1.2);

  > span {
    font-size: 15px;
  }
`

const Auth = () => {
  const [codeRequested, setCodeRequested] = useState(false)
  const { control, formState, handleSubmit, setError } = useForm({
    mode: 'onChange',
  })

  const requestLoginMutation = useRequestLogin()
  const loginMutation = useLogin()

  const onSubmit = async ({ phoneNumber, code }) => {
    if (!codeRequested) {
      try {
        const { success } = await requestLoginMutation.mutateAsync(phoneNumber)
        if (success) setCodeRequested(true)
      } catch (e) {
        setError('phoneNumber', {
          type: 'manual',
          message: e.message,
        })
      }
    } else {
      try {
        await loginMutation.mutateAsync({ phoneNumber, code })
      } catch (e) {
        setError('code', {
          type: 'manual',
          message: e.message,
        })
      }
    }
  }

  return (
    <Container>
      <LogoText>
        Disco <span>Admin</span>
      </LogoText>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {!codeRequested ? (
          <PhoneInput
            control={control}
            name="phoneNumber"
            placeholder="Enter Phone Number"
            required
          />
        ) : (
          <Input
            required
            control={control}
            name="code"
            placeholder="Enter Code"
            type="Text"
            autoComplete="one-time-code"
            maxLength={4}
          />
        )}
        <Button
          disabled={
            !formState.isValid ||
            requestLoginMutation.isLoading ||
            Object.keys(formState.errors).length > 0
          }
        >
          {!codeRequested ? 'Next' : 'Login'}
        </Button>
      </Form>
    </Container>
  )
}

Auth.propTypes = {}

export default Auth
