/**
 * @file
 * @author Alwyn Tan
 */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { AnimatePresence } from 'framer-motion'
import dayjs from 'dayjs'
import CreateEditDrop from 'pages/CreateEditDrop'
import MetadataRow from './MetadataRow'

const Container = styled.div`
  width: 375px;
`

const Header = styled.div`
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const EditButton = styled.p`
  padding: 5px 10px;
  background-color: ${({ theme }) => theme.Action};
  color: white;
  border-radius: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  cursor: pointer;
  user-select: none;
`

const Content = styled.div`
  padding-top: 15px;
`

const DropMetadata = ({ drop }) => {
  const [showEdit, setShowEdit] = useState(false)

  const handleEditClick = () => {
    setShowEdit(true)
  }

  const handleCloseEdit = () => {
    setShowEdit(false)
  }

  return (
    <>
      <AnimatePresence>
        {showEdit && <CreateEditDrop onClose={handleCloseEdit} drop={drop} />}
      </AnimatePresence>
      <Container>
        <Header>
          <h5>Metadata</h5>
          {drop && <EditButton onClick={handleEditClick}>Edit</EditButton>}
        </Header>
        <Content>
          {!drop ? (
            <p>Select a drop on the left to display metadata</p>
          ) : (
            <>
              <MetadataRow title="ID" content={drop.id} />
              <MetadataRow title="Title" content={drop.title} />
              <MetadataRow title="Description" content={drop.description} />
              <MetadataRow title="Location Name" content={drop.location} />
              <MetadataRow title="Address" content={drop.address} />
              <MetadataRow title="Link" content={drop.link} />

              {/* TODO: FOR DEMO */}
              <MetadataRow title="Time" content={drop.time} />
              <MetadataRow title="Button Text" content={drop.buttonText} />

              <MetadataRow
                title="Last Updated"
                content={dayjs(drop.updatedAt).format(
                  'DD MMM YYYY, hh:mm:ss a'
                )}
              />
              <MetadataRow
                title="Created On"
                content={dayjs(drop.createdAt).format(
                  'DD MMM YYYY, hh:mm:ss a'
                )}
              />
            </>
          )}
        </Content>
      </Container>
    </>
  )
}

DropMetadata.propTypes = {
  drop: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    location: PropTypes.string,
    address: PropTypes.string,
    link: PropTypes.string,
    updatedAt: PropTypes.string,
    createdAt: PropTypes.string,
    // TODO: FOR DEMO
    time: PropTypes.string,
    buttonText: PropTypes.string,
  }),
}

DropMetadata.defaultProps = { drop: null }

export default DropMetadata
