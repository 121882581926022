/**
 * @file
 * @author Alwyn Tan
 */

import React, { useContext, useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import RootContext from 'RootContext'
import Auth from 'pages/Auth'
import Home from 'pages/Home'
import useSilentLogin from 'hooks/query/user/useSilentLogin'
import LoadingScreen from 'pages/LoadingScreen'

const App = () => {
  const { user } = useContext(RootContext)
  const { mutate, isLoading } = useSilentLogin()

  // note: annoying bug where setcontext takes longer to execute than this return func
  // figure out a way to fix it. causing route refreshes
  useEffect(() => {
    mutate()
  }, [mutate])

  if (isLoading) return <LoadingScreen />

  if (user?.accessToken) {
    return (
      <Switch>
        <Route exact path="/" component={Home} />
        <Redirect from="/login" to="/" />
        <Redirect from="*" to="/404" />
      </Switch>
    )
  }

  return (
    <Switch>
      <Route exact path="/login" component={Auth} />
      <Redirect from="*" to="/login" />
    </Switch>
  )
}

export default App
