/**
 * @file Drop Card
 * @author Alwyn Tan
 */

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import pin from '../../../images/pin.png'
import calendar from '../../../images/calendar.png'

const Container = styled.div`
  position: relative;
  width: 375px;
  height: 812px;
  background-color: #242424;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding-bottom: 40px;
  padding-left: 15px;
  margin-bottom: 15px;
  opacity: ${({ active }) => (active ? 0.8 : 1)};
  cursor: ${({ active, setActiveDrop }) =>
    active || !setActiveDrop ? 'default' : 'pointer'};

  :before {
    content: ' ';
    position: absolute;
    border: 4px solid white;
    border-width: ${({ active }) => (active ? 4 : 0)}px;
    z-index: 1;
    border-radius: 10px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`

const Title = styled.h2`
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  z-index: 1;
  margin-bottom: 10px;

  > span {
    font-style: italic;
    opacity: 0.5;
  }
`

const Descriptor = styled.div`
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 6px;
  border-radius: 6px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  display: flex;
  cursor: pointer;
  max-width: 100%;
  align-items: center;

  > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    > span {
      font-style: italic;
      opacity: 0.5;
    }
  }
`

const Icon = styled.div`
  background-image: url(${({ src }) => src});
  background-size: contain;
  background-repeat: no-repeat;
  width: 10px;
  height: 12.5px;
  min-width: 10px;
  margin-right: 6px;
`

const StyledVideo = styled.video`
  border-radius: inherit;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
`

const Button = styled.div`
  z-index: 1;
  position: absolute;
  right: 15px;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(115px);
  border-radius: 100px;
  padding: 10px 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
`

const aspectRatio = 375 / 812

const DropCard = ({ width, drop, active, setActiveDrop }) => {
  const [vidSrc, setVidSrc] = useState(drop.video)
  const [thumbnailSrc, setThumbnailSrc] = useState(drop.thumbnail)

  useEffect(() => {
    if (typeof drop.video === 'object') {
      const url = URL.createObjectURL(drop.video)
      setVidSrc(url)
      return () => URL.revokeObjectURL(url)
    }
    setVidSrc(drop.video)
    return () => {}
  }, [drop.video])

  useEffect(() => {
    if (typeof drop.thumbnail === 'object') {
      const url = URL.createObjectURL(drop.thumbnail)
      setThumbnailSrc(url)
      return () => URL.revokeObjectURL(url)
    }
    setThumbnailSrc(drop.thumbnail)
    return () => {}
  }, [drop.thumbnail])

  // update if drop changed
  useEffect(() => {
    if (active) setActiveDrop(drop)
  }, [active, drop, setActiveDrop])

  const handleLocationClick = () => {
    window.open(`https://maps.google.com/?q=${drop?.address}`, '_blank')
  }

  const handleContainerClick = () => {
    if (setActiveDrop) setActiveDrop(drop)
  }

  return (
    <Container
      active={active}
      style={{ width, height: Math.floor(width / aspectRatio) }}
      onClick={handleContainerClick}
      setActiveDrop={setActiveDrop} // for styling
    >
      <Title>{drop.title || <span>Title</span>}</Title>
      {drop.time && (
        <Descriptor style={{ marginBottom: 5 }}>
          <Icon src={calendar} />
          <span>{drop.time}</span>
        </Descriptor>
      )}
      <Descriptor onClick={handleLocationClick}>
        <Icon src={pin} />
        <span>
          {drop.location || (drop.address || '').split(',')[0] || (
            <span>Location or Address</span>
          )}
        </span>
      </Descriptor>
      {drop.buttonText && <Button>{drop.buttonText}</Button>}
      <StyledVideo
        autoPlay
        muted
        loop
        playsInline
        src={vidSrc}
        poster={thumbnailSrc}
      />
    </Container>
  )
}

DropCard.propTypes = {
  active: PropTypes.bool,
  drop: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    location: PropTypes.string,
    address: PropTypes.string,
    // TODO: FOR DEMO
    time: PropTypes.string,
    buttonText: PropTypes.string,
    video: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    thumbnail: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }).isRequired,
  width: PropTypes.number,
  setActiveDrop: PropTypes.func,
}

DropCard.defaultProps = { active: false, width: 375, setActiveDrop: null }

export default DropCard
