/**
 * @file
 * @author Alwyn Tan
 */

import DropList from 'components/compound/Drop/DropList'
import DropMetadata from 'components/compound/Drop/DropMetadata'
import useLogout from 'hooks/query/user/useLogout'
import React, { useState } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  width: 100%;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const LogoText = styled.span`
  font-family: Hobeaux;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  text-transform: uppercase;
  color: white;

  > span {
    font-size: 15px;
  }
`

const Logout = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: white;
  cursor: pointer;
`

const Content = styled.div`
  width: 100%;
  height: 0;
  flex: 1;
  display: flex;
  justify-content: row;
  justify-content: center;
`

const Divider = styled.div`
  height: calc(100% - 40px);
  width: 1px;
  margin: 0 40px;
  background-color: rgba(255, 255, 255, 0.2);
`

const Home = () => {
  const [activeDrop, setActiveDrop] = useState(null)
  const logoutMutation = useLogout()

  const handleLogout = () => {
    logoutMutation.mutate()
  }

  return (
    <Container>
      <Header>
        <LogoText>
          Disco <span>Admin</span>
        </LogoText>
        <Logout onClick={handleLogout}>Logout</Logout>
      </Header>
      <Content>
        <DropList activeDrop={activeDrop} setActiveDrop={setActiveDrop} />
        <Divider />
        <DropMetadata drop={activeDrop} />
      </Content>
    </Container>
  )
}

export default Home
