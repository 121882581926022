/**
 * @file Drop List
 * @author Alwyn Tan
 */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import useGetDrops from 'hooks/query/drop/useGetDrops'
import CreateEditDrop from 'pages/CreateEditDrop'
import { AnimatePresence } from 'framer-motion'
import DropCard from './DropCard'
import InfiniteScroll from '../InfiniteScroll'

const Container = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const NewButton = styled.p`
  padding: 5px 10px;
  background-color: ${({ theme }) => theme.Action};
  border-radius: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  cursor: pointer;
`

const ScrollContainer = styled.div`
  flex: 1;
  padding-top: 10px;
  overflow-y: scroll;
`

const DropList = ({ activeDrop, setActiveDrop }) => {
  const [showCreate, setShowCreate] = useState(false)

  const handleNewClick = () => {
    setShowCreate(true)
  }

  const handleCloseCreate = () => {
    setShowCreate(false)
  }

  return (
    <>
      <AnimatePresence>
        {showCreate && <CreateEditDrop onClose={handleCloseCreate} />}
      </AnimatePresence>
      <Container>
        <Header>
          <h5>Recent Drops</h5>
          <NewButton onClick={handleNewClick}>+ New</NewButton>
        </Header>
        <ScrollContainer>
          <InfiniteScroll
            infiniteQueryHook={useGetDrops}
            endOfListComponent={
              <p style={{ opacity: 0.5, paddingTop: 20, textAlign: 'center' }}>
                No more active events to show 👾
              </p>
            }
          >
            {drop => (
              <DropCard
                width={250}
                key={drop.id}
                drop={drop}
                active={activeDrop?.id === drop.id}
                setActiveDrop={setActiveDrop}
              />
            )}
          </InfiniteScroll>
        </ScrollContainer>
      </Container>
    </>
  )
}

DropList.propTypes = {
  activeDrop: PropTypes.shape({
    id: PropTypes.string.isRequired,
    location: PropTypes.string,
    address: PropTypes.string,
  }),
  setActiveDrop: PropTypes.func,
}

DropList.defaultProps = { activeDrop: null, setActiveDrop: () => {} }

export default DropList
