/**
 * @file
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  :not(:first-child) {
    margin-top: 25px;
  }
`

const Title = styled.p`
  font-weight: bold;
  padding-bottom: 2px;
`

const Description = styled.p``

const UnsetContent = styled.p`
  opacity: 0.4;
  font-style: italic;
`

const MetadataRow = ({ title, content }) => (
  <Container>
    <Title>{title}</Title>
    {content ? (
      <Description>{content}</Description>
    ) : (
      <UnsetContent>Unset</UnsetContent>
    )}
  </Container>
)

MetadataRow.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
}

MetadataRow.defaultProps = {
  content: '',
}

export default MetadataRow
