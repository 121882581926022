/* eslint-disable import/prefer-default-export */

/**
 * @file General ungrouped utility functions
 * @author Alwyn Tan
 */

import Compressor from 'compressorjs'

export const compressThumbnailImage = async file =>
  new Promise((resolve, reject) => {
    new Compressor(file, {
      width: 1080,
      height: 1920,
      success: resolve,
      error: reject,
    })
  })
