/**
 * @file Create/Edit Drop Page
 * @author Alwyn Tan
 */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { motion } from 'framer-motion'
import Input from 'components/simple/Input'
import DropCard from 'components/compound/Drop/DropCard'
import useUpdateDrop from 'hooks/query/drop/useUpdateDrop'
import useCreateDrop from 'hooks/query/drop/useCreateDrop'

const Container = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
  background-color: #131313;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
`

const Title = styled.h5`
  margin-bottom: 30px;
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: auto;
`

const Form = styled.form`
  width: 375px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  > :not(h5) {
    margin-bottom: 25px;
  }
`

const Divider = styled.div`
  height: 100%;
  width: 1px;
  margin: 0 50px;
  background-color: rgba(255, 255, 255, 0.2);
`

const Preview = styled.div`
  width: 375px;
  height: 100%;
`

const XButton = styled.div`
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;

  :after {
    font-size: 30px;
    display: inline-block;
    content: '\\00d7'; /* This will render the 'X' */
  }
`

const SubmitButton = styled.button`
  background-color: ${({ theme }) => theme.Action};
  color: white;
  border: none;
  outline: none;
  padding: 10px 55px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  align-self: flex-end;
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`

const CreateEditDrop = ({ drop, onClose }) => {
  const { control, watch, formState, handleSubmit } = useForm({
    mode: 'onChange',
  })
  const updateDropMutation = useUpdateDrop()
  const createDropMutation = useCreateDrop()

  useEffect(() => {
    const handleKeyDown = e => {
      if (e.key === 'Escape') onClose()
    }
    window.addEventListener('keydown', handleKeyDown)

    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [onClose])

  useEffect(() => {
    if (updateDropMutation.isSuccess || createDropMutation.isSuccess) onClose()
  }, [updateDropMutation.isSuccess, createDropMutation.isSuccess, onClose])

  const onSubmit = async fields => {
    const dirtyValues = (dirtyFields, allValues) => {
      if (dirtyFields === true || Array.isArray(dirtyFields)) return allValues
      // Here, we have an object
      return Object.fromEntries(
        Object.keys(dirtyFields).map(key => [
          key,
          dirtyValues(dirtyFields[key], allValues[key]),
        ])
      )
    }

    const updatedFields = dirtyValues(formState.dirtyFields, fields)

    if (drop.id)
      updateDropMutation.mutate({ id: drop.id, fields: updatedFields })
    else createDropMutation.mutate(updatedFields)
  }
  // default values

  const isLoading = updateDropMutation.isLoading || createDropMutation.isLoading

  return (
    <Container
      initial={{ top: '100%' }}
      animate={{ top: 0 }}
      exit={{ top: '100%' }}
      transition={{ type: 'spring', stiffness: 125, damping: 20 }}
    >
      <XButton onClick={onClose} />
      <FormWrapper>
        <Title>Add a Drop</Title>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Input
            required
            title="Title"
            placeholder="Title"
            control={control}
            name="title"
            defaultValue={drop.title}
          />
          <Input
            required
            title="Description"
            placeholder="Description"
            control={control}
            name="description"
            defaultValue={drop.description}
          />
          <Input
            title="Location Name"
            placeholder="Location Name"
            control={control}
            name="location"
            defaultValue={drop.location}
          />
          <Input
            required
            title="Address"
            placeholder="Address"
            control={control}
            name="address"
            defaultValue={drop.address}
          />
          <Input
            title="Link"
            placeholder="Link"
            control={control}
            name="link"
            defaultValue={drop.link}
          />
          <Input
            required
            title="Video"
            type="file"
            accept="video/mp4, video/mpeg, video/quicktime"
            control={control}
            name="video"
            defaultValue={drop.video}
          />
          <Input
            required
            title="Video Preview"
            type="file"
            accept="video/mp4, video/mpeg, video/quicktime"
            control={control}
            name="videoPreview"
            defaultValue={drop.videoPreview}
          />
          <Input
            required
            title="Thumbnail"
            type="file"
            accept="image/png, image/jpeg"
            control={control}
            name="thumbnail"
            defaultValue={drop.thumbnail}
          />
          {/* TODO: FOR DEMO */}
          <Input
            title="Time"
            placeholder="Time"
            control={control}
            name="time"
            defaultValue={drop.time}
          />
          <Input
            title="Button Text"
            placeholder="Button Text"
            control={control}
            name="buttonText"
            defaultValue={drop.buttonText}
          />
          <SubmitButton disabled={!formState.isDirty || isLoading}>
            {isLoading ? 'Loading...' : 'Save'}
          </SubmitButton>
        </Form>
      </FormWrapper>
      <Divider />
      <Preview>
        <h5>Preview</h5>
        <DropCard width={375} drop={{ ...drop, ...watch() }} />
      </Preview>
    </Container>
  )
}

CreateEditDrop.propTypes = {
  onClose: PropTypes.func,
  drop: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    location: PropTypes.string,
    address: PropTypes.string,
    link: PropTypes.string,
    // TODO: FOR DEMO
    time: PropTypes.string,
    buttonText: PropTypes.string,
    video: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    videoPreview: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    thumbnail: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
}

CreateEditDrop.defaultProps = {
  onClose: () => {},
  drop: {
    id: '',
    title: '',
    description: '',
    location: '',
    address: '',
    link: '',
    video: undefined,
    thumbnail: undefined,
  },
}

export default CreateEditDrop
