/**
 * @file Silent Login Hook
 * @author Alwyn Tan
 */

import { useContext } from 'react'
import { useMutation } from 'react-query'
import RootContext from 'RootContext'
import { post } from 'utils/api'

const SILENT_LOGIN_URL = '/api/user/silent-login'

const postSilentLogin = async () =>
  post(SILENT_LOGIN_URL, { admin: true }, { includeCredentials: true })

const useSilentLogin = () => {
  const { setUser } = useContext(RootContext)

  return useMutation(async () => {
    const { accessToken, user } = await postSilentLogin()
    setUser({ ...user, accessToken })

    return { user }
  })
}

export default useSilentLogin
