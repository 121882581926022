/**
 * @file Input Component
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useController } from 'react-hook-form'

const Container = styled.div`
  width: 100%;

  > p {
    padding-bottom: 5px;
  }
`

const StyledInput = styled.input`
  background-color: #171818;
  padding: 13.5px 15px;
  height: 50px;
  width: 100%;
  max-width: 375px;
  outline: none;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: normal;
  -webkit-appearance: none;
  border: ${({ error }) => (error ? 1 : 0)}px solid red;
  color: white;
`

const Error = styled.p`
  padding: 5px 0 0 10px;
  color: ${({ theme }) => theme.Error};
`

const Input = ({
  control,
  name,
  defaultValue,
  required,
  validate,
  title,
  style,
  ...inputProps
}) => {
  const { field, fieldState } = useController({
    name,
    control,
    defaultValue,
    rules: { required, validate },
  })

  const handleOnChange = e => {
    if (inputProps.type === 'file') field.onChange(e.target.files[0])
    else field.onChange(e.target.value)
  }

  const transform = {
    input: v => {
      if (inputProps.type === 'file') return undefined
      return v
    },
  }

  return (
    <Container style={style}>
      {title && <p>{title}</p>}
      <StyledInput
        {...inputProps}
        {...field}
        onChange={handleOnChange}
        value={transform.input(field.value)}
        error={fieldState.error?.message || fieldState?.invalid}
      />
      {(fieldState.error?.message || fieldState?.invalid) && (
        <Error>{fieldState.error?.message || 'Required'}</Error>
      )}
    </Container>
  )
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  validate: PropTypes.func,
  title: PropTypes.string,
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
}

Input.defaultProps = {
  defaultValue: '',
  required: false,
  validate: null,
  title: '',
  style: {},
}

export default Input
