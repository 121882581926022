/**
 * @file Loading Screen
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
`

const LoadingScreen = () => (
  <Container>
    <h3>Loading...</h3>
  </Container>
)

export default LoadingScreen
