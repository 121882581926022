/**
 * @file Login Hook
 * @author Alwyn Tan
 */

import { useContext } from 'react'
import { useMutation } from 'react-query'
import RootContext from 'RootContext'
import { post } from 'utils/api'

const LOGIN_URL = '/api/user/login'

const postLogin = async ({ phoneNumber, code }) =>
  post(
    LOGIN_URL,
    { phoneNumber, code, admin: true },
    { includeCredentials: true }
  )

const useLogin = () => {
  const { setUser } = useContext(RootContext)

  return useMutation(async ({ phoneNumber, code }) => {
    const { accessToken, user } = await postLogin({ phoneNumber, code })
    setUser({ ...user, accessToken })

    return { user }
  })
}

export default useLogin
