/**
 * @file Get drops query
 * @author Alwyn Tan
 */

import { useContext } from 'react'
import { useInfiniteQuery } from 'react-query'
import RootContext from 'RootContext'
import { get, makeURLWithQueryParams } from 'utils/api'

const GET_DROPS_URL = '/api/drop/'

const getDrops = async (prevItem, accessToken) => {
  const url = makeURLWithQueryParams(GET_DROPS_URL, {
    prevID: prevItem?.id,
  })
  return get(url, { accessToken })
}

const useGetDrops = () => {
  const { user } = useContext(RootContext)
  return useInfiniteQuery(
    'drops',
    async ({ pageParam }) => getDrops(pageParam, user.accessToken),
    {
      // normalize data for flatlist
      select: data =>
        data?.pages?.reduce((acc, curr) => [...acc, ...curr.drops], []),
      getNextPageParam: prevPage => {
        if (prevPage?.canLoadMore)
          return prevPage?.drops[prevPage?.drops.length - 1]
        return undefined
      },
    }
  )
}

export default useGetDrops
